import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import Text from '../Text';
import styles from './index.module.scss';

const ICON_NAME_BY_TYPE = {
    danger: 'alert-circle',
    warning: 'alert-circle',
    info: 'alert-info',
    success: 'check-circle'
};

const CLASS_NAME_BY_TYPE = {
    danger: styles['alert_danger'],
    warning: styles['alert_warning'],
    info: styles['alert_info'],
    success: styles['alert_success']
};

const Alert = ({className, type, title, description, children, onClose}) => {
    const alertClassName = classnames(styles['alert'], CLASS_NAME_BY_TYPE[type], className, {
        [styles['alert_enlarged']]: !title // FYI: Required by design, increasing the font size for text if the alert not titled. (Vlad, 11.09.20)
    });
    const alertIconName = ICON_NAME_BY_TYPE[type];

    return (
        <div className={alertClassName}>
            <div className={styles['alert-info']}>
                <Icon className={styles['alert-info__icon']} type={alertIconName}/>

                <div>
                    {title && <Text className={styles['alert-info__title']} fontWeight='bold'>{title}</Text>}
                    <Text className={styles['alert-info__description']}>{description}</Text>
                </div>
            </div>

            <div className={styles['alert-action-bar']}>
                {children && <div className={styles['alert-action-bar__custom-actions']}>{children}</div>}
                {onClose && <Icon className={styles['alert-action-bar__close-icon']} type='close' onClick={onClose}/>}
            </div>
        </div>
    );
};

Alert.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    onClose: PropTypes.func
};

Alert.defaultProps = {
    type: 'warning',
    className: ''
};

export {Alert as TestableAlert};
export default React.memo(Alert);
