import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const TYPES = {
    primary: 'primary',
    danger: 'danger',
    light: 'light',
    link: 'link',
    success: 'success',
    successReverse: 'success-reverse',
    secondary: 'secondary'
};

const SIZES = {lg: 'lg', md: 'md', sm: 'sm'};

const CLASS_NAME_BY_TYPE = {
    [TYPES.primary]: styles['button_primary'],
    [TYPES.danger]: styles['button_danger'],
    [TYPES.light]: styles['button_light'],
    [TYPES.link]: styles['button_link'],
    [TYPES.success]: styles['button_success'],
    [TYPES.successReverse]: styles['button_success-reverse'],
    [TYPES.secondary]: styles['button_secondary']
};

const CLASS_NAME_BY_SIZE = {
    [SIZES.lg]: styles['button_lg'],
    [SIZES.md]: styles['button_md'],
    [SIZES.sm]: styles['button_sm']
};

const Button = ({children, className, type, size, isSubmit, ...props}) => {
    const buttonClassName = classnames(styles['button'], '_button', CLASS_NAME_BY_TYPE[type], CLASS_NAME_BY_SIZE[size], className);

    return (
        <button {...props} type={isSubmit ? 'submit' : 'button'} className={buttonClassName}>
            <span className={styles['button__title']}>{children}</span>
        </button>
    );
};

Button.propTypes = {
    disabled: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    isSubmit: PropTypes.bool,
    children: PropTypes.node.isRequired
};

Button.defaultProps = {
    type: 'primary',
    size: 'md',
    isSubmit: false,
    className: ''
};

export {Button as TestableButton};
export default React.memo(Button);
