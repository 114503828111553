import React from 'react';

const withReduxFormFieldData = Component => {
    return class WithReduxFormFieldData extends React.PureComponent {
        render = () => {
            const {input, meta, ...props} = this.props;
            const {valid: isValid, error: errorMessage, touched: isTouched} = meta;
            const componentProps = {...input, ...props, isValid, errorMessage, isTouched};

            return <Component {...componentProps}/>;
        }
    };
};

export {withReduxFormFieldData as testableWithReduxFormFieldData};
export default withReduxFormFieldData;
