import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const ContentSection = React.forwardRef(({children, className}, ref) => {
    return <div className={classnames(styles['content-section'], className)} ref={ref}>{children}</div>;
});

ContentSection.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

ContentSection.defaultProps = {
    className: ''
};

export {ContentSection as TestableContentSection};
export default React.memo(ContentSection);
