import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Heading from '../Heading';
import styles from './index.module.scss';

const PopupContent = ({children, title, subtitle, actionBar, isScrollableContent}) => {
    const popupContentClassName = classnames(styles['popup-content'], {[styles['popup-content_scrollable-content']]: isScrollableContent});

    return (
        <div className={popupContentClassName}>
            <div className={styles['popup-header']}>
                <Heading className={styles['popup-header__title']} gradation={4}>{title}</Heading>
                {subtitle && <Heading className={styles['popup-header__subtitle']}>{subtitle}</Heading>}
            </div>

            <div className={styles['popup-body']}>{children}</div>

            {actionBar && <div className={styles['popup-action-bar']}>{actionBar}</div>}
        </div>
    );
};

PopupContent.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    actionBar: PropTypes.node,
    isScrollableContent: PropTypes.bool
};

export {PopupContent as TestablePopupContent};
export default React.memo(PopupContent);
