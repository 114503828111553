import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Row as GridRow} from 'react-flexbox-grid';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';
import styles from './index.module.scss';

const SIZES = {
    xxs: 'xxs',
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xlg: 'xlg'
};

const CLASS_NAME_BY_COLUMN_GAP_SIZE = {
    [SIZES.xxs]: styles['row_column-gap-xxs'],
    [SIZES.xs]: styles['row_column-gap-xs'],
    [SIZES.sm]: styles['row_column-gap-sm'],
    [SIZES.md]: styles['row_column-gap-md'],
    [SIZES.lg]: styles['row_column-gap-lg'],
    [SIZES.xlg]: styles['row_column-gap-xlg']
};

const CLASS_NAME_BY_ROW_GAP_SIZE = {
    [SIZES.xxs]: styles['row_row-gap-xxs'],
    [SIZES.xs]: styles['row_row-gap-xs'],
    [SIZES.sm]: styles['row_row-gap-sm'],
    [SIZES.md]: styles['row_row-gap-md'],
    [SIZES.lg]: styles['row_row-gap-lg'],
    [SIZES.xlg]: styles['row_row-gap-xlg']
};

const Row = ({className, children, columnGap, rowGap, ...props}) => {
    const rowClassName = classnames(styles['row'], CLASS_NAME_BY_COLUMN_GAP_SIZE[columnGap], CLASS_NAME_BY_ROW_GAP_SIZE[rowGap], className);

    return <GridRow {...props} className={rowClassName}>{children}</GridRow>;
};

Row.propTypes = {
    className: PropTypes.string,
    columnGap: PropTypes.string,
    rowGap: PropTypes.string,
    children: PropTypes.node.isRequired
};

Row.defaultProps = {
    className: ''
    // columnGap: 'xs' // TODO: Add default values after adding gap props in all projects. Otherwise we can broke current layouts. (Pasha, 20.05.2021)
    // rowGap: 'md'
};

export {Row as TestableRow};
export default React.memo(Row);
