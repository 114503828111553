import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../../static/icons/icons.css';
import styles from './index.module.scss';

const Icon = ({className, type, ...props}) => <i {...props} className={classnames(styles['icon'], '_icon', `_icon_${type}`, className)}/>;

Icon.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string
};

Icon.defaultProps = {
    className: ''
};

export {Icon as TestableIcon};
export default React.memo(Icon);
