import {format, parseISO, getTime, isValid} from 'date-fns';

export const pipe = (...funcs) => {
    const _pipe = (prevFunc, currFunc) => (...arg) => currFunc(prevFunc(...arg));

    return funcs.reduce(_pipe);
};

export const negate = val => !val;

export const negateFunc = func => pipe(func, negate);

export const compose = (...funcs) => pipe(...funcs.reverse());

export const pass = val => val;

export const partial = (func, ...params) => (...args) => func(...params, ...args);

export const equal = (val, other) => val === other;

export const getItemKeyValue = key => obj => obj[key];

export const getEqual = (value, key) => pipe(key ? getItemKeyValue(key) : pass, partial(equal, value));

export const getUnfilledArray = (length = 0) => Array(length).fill(undefined);

export const getDelimitedNum = (num = 0) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getRandomColor = () => `#${((1 << 24) * Math.random() | 0).toString(16)}`;

export const isPrimitive = val => val !== Object(val);

export const isString = val => equal(typeof val, 'string');

export const isBoolean = val => equal(typeof val, 'boolean');

export const isEmpty = obj => {
    if (isPrimitive(obj)) {
        return !obj;
    }
    if (Array.isArray(obj)) {
        return !obj.length;
    }

    return isEmpty(Object.keys(obj));
};

export const isEven = number => number % 2 === 0;

export const isNumber = val => equal(typeof val, 'number');

export const debounce = (func, delay = 100) => {
    let timeout = null;

    return (...args) => {
        clearTimeout(timeout);

        return new Promise(resolve => {
            const next = () => resolve(func(...args));

            timeout = setTimeout(next, delay);
        });
    };
};

export const getDataUrlFromFile = file => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
            fileReader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.readAsDataURL(file);
    });
};

export const rgbToHex = (r, g, b) => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

export const getFileName = file => file && file.split('/').pop();

export const getFileFormat = name => name && name.substring(name.lastIndexOf('.')).toLowerCase();

export const bytesToMegabytes = bytes => bytes / 1024 / 1000;

export const getEscapedHtml = html => {
    const elem = document.createElement('div');
    elem.innerHTML = html;

    return elem.innerHTML;
};

export const throttle = (func, delay = 100) => {
    let timeout = null;

    return (...args) => {
        const next = () => {
            func(...args);
            timeout = null;
        };

        timeout = timeout ?? setTimeout(next, delay);
    };
};

export const formatDate = (date, dateFormat, options = {}) => format(date, dateFormat, options);

export const parseDateISO = (date, options = {}) => parseISO(date, options);

export const getDateTime = date => getTime(date);

export const isDateValid = date => isValid(date);
