import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import withEnhancedField from '../../HOC/withEnhancedField';
import styles from './index.module.scss';

const Checkbox = ({className, value, caption, ...props}) => (
    <label className={classnames(styles['checkbox'], '_checkbox', className)}>
        <input type='checkbox' className={styles['checkbox__input']} checked={value} {...props}/>
        <div className={styles['checkbox-checkmark']}><Icon className={styles['checkbox-checkmark__icon']} type='check'/></div>
        {caption && <div className={styles['checkbox__caption']}>{caption}</div>}
    </label>
);

Checkbox.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    caption: PropTypes.string,
    onChange: PropTypes.func
};

Checkbox.defaultProps = {
    value: false,
    className: '',
    caption: ''
};

export {Checkbox as TestableCheckbox};
export default withEnhancedField(Checkbox);
