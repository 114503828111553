import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '../../Components/Tooltip';
import Icon from '../../Components/Icon';
import {equal} from '../../utils';
import {INPUT_TYPES} from '../../constants';
import styles from './index.module.scss';

const CHARS_ALERT_LIMIT = 20;
const HIGHLIGHT_TYPES = {
    success: 'success',
    warning: 'warning',
    danger: 'danger',
    none: 'none'
};

// TODO: think how to improve it (14.08.2020, Oleh);
const isEvent = value => value?.stopPropagation && value?.preventDefault;

const withEnhancedField = Component => {
    class WithEnhancedField extends React.PureComponent {
        // FYI: first param in onChange is event for custom components and value only for Selects and DatePickers
        // because of libraries they based on (14.08.2020, Oleh)
        onChange = entity => {
            const value = isEvent(entity) ? entity.target.value : entity;
            const {maxLength, isAllowInputOverflow, onChange} = this.props;
            const isLimitExceeded = maxLength && `${value}`.length > maxLength; // HACK: preventing of strange behaviour in Safari

            if (isLimitExceeded && !isAllowInputOverflow) {
                return false;
            }

            onChange(entity);
        };

        getIsErrorShowed = () => this.props.isValidationDisplayed && !this.props.isValid && this.props.isTouched;

        onOpen = (...args) => {
            const {highlightType, isMenuPortal, onOpen} = this.props;

            if (!isMenuPortal) {
                return onOpen(...args);
            }

            // FYI: Should toggle body class name by highlight type for Select with isMenuPortal prop to add specific border color. In this case, select menu is created inside the body and can't get the right class name (Pasha, 4.08.2021).
            const isErrorShowed = this.getIsErrorShowed();
            const bodyClassName = isErrorShowed ? 'select-menu-danger' : `select-menu-${highlightType}`;

            document.body.classList.add(bodyClassName);
        };

        onClose = (...args) => {
            const {isMenuPortal, onClose} = this.props;

            if (!isMenuPortal) {
                return onClose(...args);
            }

            Object.values(HIGHLIGHT_TYPES).forEach(type => document.body.classList.remove(`select-menu-${type}`));
        };

        render = () => {
            const {
                highlightType,
                value,
                isValid,
                errorMessage,
                isTouched,
                isValidationDisplayed,
                maxLength,
                icon,
                iconClassName,
                iconTooltipContent,
                onIconClick,
                isRequired,
                isInfoBar,
                caption,
                description,
                ...restProps
            } = this.props;
            const isErrorShowed = this.getIsErrorShowed();

            const charsLeft = maxLength ? maxLength - `${value}`.length : null;
            const isCharsLeftAlert = charsLeft < CHARS_ALERT_LIMIT;
            const charsLeftClassName = classnames(styles['info-bar-item__chars-left'], {[styles['info-bar-item__chars-left_alert']]: isCharsLeftAlert});

            const isInfoBarShowed = isInfoBar && !equal(restProps.type, INPUT_TYPES.hidden) && (isErrorShowed || maxLength || description);
            const tooltipProps = {className: styles['icon-wrapper'], content: iconTooltipContent, isOpened: iconTooltipContent ? null : false};
            const iconClassNames = classnames(styles['icon-wrapper__icon'], iconClassName);

            const requiredCaptionClassName = classnames(styles['field-caption'], {[styles['field-caption_required']]: isRequired});
            const enhancedCaption = caption && <div className={requiredCaptionClassName}>{caption}</div>;
            const enhancedFieldWrapperClassName = classnames(styles['enhanced-field-wrapper'], {[styles[`enhanced-field-wrapper_${highlightType}`]]: highlightType}, {[styles['enhanced-field-wrapper_danger']]: isErrorShowed});
            const componentProps = {...restProps, value, caption: enhancedCaption, onChange: this.onChange, onOpen: this.onOpen, onClose: this.onClose};

            return (
                <div className={enhancedFieldWrapperClassName}>
                    <div className={styles['field-wrapper']}>
                        <Component {...componentProps}/>

                        {icon && (
                            <Tooltip {...tooltipProps}>
                                <Icon className={iconClassNames} type={icon} onClick={onIconClick}/>
                            </Tooltip>
                        )}
                    </div>

                    {isInfoBarShowed && (
                        <div className={styles['info-bar']}>
                            <div className={styles['info-bar-item']}>
                                {isErrorShowed && <div className={styles['info-bar-item__error-message']}>{errorMessage}</div>}

                                {description && <div className={styles['info-bar-item__description']}>{description}</div>}
                            </div>

                            <div className={styles['info-bar-item']}>
                                {maxLength && <div className={charsLeftClassName}>Characters left: {charsLeft}</div>}
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    }

    WithEnhancedField.propTypes = {
        highlightType: PropTypes.oneOf(Object.values(HIGHLIGHT_TYPES)),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object, PropTypes.bool]),
        isValid: PropTypes.bool,
        errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        isTouched: PropTypes.bool,
        isValidationDisplayed: PropTypes.bool,
        maxLength: PropTypes.number,
        icon: PropTypes.string,
        iconClassName: PropTypes.string,
        iconTooltipContent: PropTypes.string,
        description: PropTypes.string,
        caption: PropTypes.string,
        isRequired: PropTypes.bool,
        isInfoBar: PropTypes.bool,
        isMenuPortal: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        onIconClick: PropTypes.func,
        isAllowInputOverflow: PropTypes.bool
    };

    WithEnhancedField.defaultProps = {
        highlightType: HIGHLIGHT_TYPES.none,
        isValidationDisplayed: true,
        isInfoBar: true,
        isMenuPortal: false,
        iconClassName: '',
        onIconClick: () => {},
        onOpen: () => {},
        onClose: () => {},
        isAllowInputOverflow: false
    };

    return WithEnhancedField;
};

export {withEnhancedField as testableWithEnhancedField};
export default withEnhancedField;
