import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import styles from './index.module.scss';

const CLASS_NAMES_BY_MODAL_TYPE = {
    simple: classnames(styles['popup-modal_simple'], '_popup-modal_simple'),
    right: classnames(styles['popup-modal_right'], '_popup-modal_right'),
    fullscreen: classnames(styles['popup-modal_fullscreen'], '_popup-modal_fullscreen')
};

class Popup extends React.PureComponent {
    componentDidUpdate = () => {
        const {isOpened} = this.props;

        document.body.style.overflow = isOpened ? 'hidden' : 'initial';
    };

    render = () => {
        const {isOpened, modalType, children, onClose} = this.props;
        const modalClassName = classnames(styles['popup-modal'], CLASS_NAMES_BY_MODAL_TYPE[modalType]);

        return isOpened && (
            <div className={styles['popup']}>
                <div className={modalClassName}>
                    <div className={styles['popup-modal__close-button']}>
                        <Icon className={styles['modal-close-icon']} type='close' onClick={onClose}/>
                    </div>

                    <div className={styles['popup-modal__content-wrapper']}>{children}</div>
                </div>

                <div className={styles['popup__layout']} onClick={onClose}/>
            </div>
        );
    }
}

Popup.propTypes = {
    modalType: PropTypes.string,
    isOpened: PropTypes.bool,
    children: PropTypes.node,
    onClose: PropTypes.func
};

Popup.defaultProps = {
    isOpened: false
};

export {Popup as TestablePopup};
export default Popup;
