import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import {equal, getUnfilledArray} from '../../utils';
import styles from './index.module.scss';

const VISIBLE_PAGE_COUNT_LIMIT = 3;

const Pagination = ({className, unit, unitCount, pageIndex, pageCount, pageSize, canPreviousPage, canNextPage, gotoPage}) => {
    const visiblePageCount = pageCount > VISIBLE_PAGE_COUNT_LIMIT ? VISIBLE_PAGE_COUNT_LIMIT : pageCount;
    const START_PAGE_BY_EXTREME_POINTS = {0: pageIndex, [pageCount - 1]: pageIndex - (visiblePageCount - 1)};
    const startPage = START_PAGE_BY_EXTREME_POINTS[pageIndex] ?? pageIndex - 1;
    const visiblePages = useMemo(() => getUnfilledArray(visiblePageCount).map((item, index) => startPage + index), [visiblePageCount, startPage]);

    const startUnit = pageIndex * pageSize + 1;
    const endUnit = Math.min(pageIndex * pageSize + pageSize, unitCount);

    const goPrev = () => gotoPage(pageIndex - 1);
    const goNext = () => gotoPage(pageIndex + 1);

    return (
        <div className={classnames(styles['pagination'], className)}>
            <div className={styles['pagination-overview']}>
                {startUnit} - {endUnit} of {unitCount} <span className={styles['pagination-overview__unit']}>{unit}</span>
            </div>

            <div className={styles['pagination-controls']}>
                <button className={classnames(styles['pagination-controls__arrow'], styles['pagination-controls__arrow_left'])} type='button' disabled={!canPreviousPage} onClick={goPrev}>
                    <Icon className={styles['control-icon']} type='arrow-fit-left'/>
                </button>

                {visiblePages.map(page => {
                    const isActive = equal(page, pageIndex);
                    const className = classnames(styles['pagination-controls__page'], {[styles['pagination-controls__page_active']]: isActive});
                    const onClick = () => gotoPage(page);

                    return <button key={page} className={className} type='button' onClick={onClick}>{page + 1}</button>;
                })}

                <button className={classnames(styles['pagination-controls__arrow'], styles['pagination-controls__arrow_right'])} type='button' disabled={!canNextPage} onClick={goNext}>
                    <Icon className={styles['control-icon']} type='arrow-fit-right'/>
                </button>
            </div>
        </div>
    );
};

Pagination.propTypes = {
    className: PropTypes.string,
    unit: PropTypes.string.isRequired,
    unitCount: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    canPreviousPage: PropTypes.bool.isRequired,
    canNextPage: PropTypes.bool.isRequired,
    gotoPage: PropTypes.func.isRequired
};

Pagination.defaultProps = {
    className: ''
};

export {Pagination as TestablePagination};
export default Pagination;
