import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const GlobalErrorMessage = ({children, className}) => <div className={classnames(styles['global-error-message'], className)}>{children}</div>;

GlobalErrorMessage.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
};

GlobalErrorMessage.defaultProps = {
    className: ''
};

export {GlobalErrorMessage as TestableGlobalErrorMessage};
export default React.memo(GlobalErrorMessage);
