import React from 'react';

const withOutsideClick = Component => {
    return class WithOutsideClick extends React.PureComponent {
        state = {
            isOpened: false
        };

        forwardRef = React.createRef();

        componentDidMount = () => document.addEventListener('click', this.onOutsideClick, false);

        componentWillUnmount = () => document.removeEventListener('click', this.onOutsideClick, false);

        onOutsideClick = ({target}) => {
            const {current: currElement} = this.forwardRef;
            const isOutside = currElement && !currElement.contains(target);

            if (isOutside) {
                this.onClose();
            }
        };

        onOpen = () => this.setState({isOpened: true});

        onClose = () => setTimeout(() => {
            if (!this.forwardRef.current) {
                return false;
            }

            this.setState({isOpened: false});
        }, 0);

        render = () => {
            const props = {
                forwardRef: this.forwardRef,
                isOpened: this.state.isOpened,
                onOpen: this.onOpen,
                onClose: this.onClose,
                ...this.props
            };

            return <Component {...props}/>;
        }
    };
};

export {withOutsideClick as testableWithOutsideClick};
export default withOutsideClick;
