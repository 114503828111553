import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import withSelectCommon from '../../HOC/withSelectCommon';
import withEnhancedField from '../../HOC/withEnhancedField';
import {compose} from '../../utils';

class Select extends React.Component {
    getNormalizedValue = value => {
        const {isMulti, options} = this.props;
        const filterBySelected = option => isMulti ? (value || []).includes(option.value) : value === option.value;
        const filteredOptions = (options || []).filter(filterBySelected);

        return isMulti ? filteredOptions : filteredOptions[0];
    };

    render = () => {
        const {value, ...props} = this.props;
        const selectProps = {...props, isSearchable: false, value: this.getNormalizedValue(value) || null};

        return <ReactSelect {...selectProps}/>;
    };
}

Select.propTypes = {
    name: PropTypes.string,
    isMulti: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    isSearchable: PropTypes.bool
};

Select.defaultProps = {
    options: [],
    placeholder: 'Please select...'
};

export {Select as TestableSelect};
export default compose(
    withEnhancedField,
    withSelectCommon
)(Select);
