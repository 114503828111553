import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {components, createFilter} from 'react-select';
import Icon from '../../Components/Icon';
import styles from './index.module.scss';

const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <Icon className={styles['dropdown-indicator']} type='triangle-down'/>
    </components.DropdownIndicator>
);

const filterOption = createFilter({stringify: option => option.label});

const withSelectCommon = Component => {
    class WithSelectCommon extends React.PureComponent {
        state = {
            isOpened: false
        };

        onOpen = () => {
            const {onOpen} = this.props;

            if (onOpen) {
                onOpen();
            }

            this.setState({isOpened: true});
        };

        onClose = () => {
            const {onBlur, onClose} = this.props;

            if (onBlur) {
                onBlur();
            }

            if (onClose) {
                onClose();
            }

            this.setState({isOpened: false});
        };

        getValue = ({value}) => value;

        onChange = selectedData => {
            const singleValue = selectedData ? this.getValue(selectedData) : null;
            const value = Array.isArray(selectedData) ? selectedData.map(this.getValue) : singleValue;

            this.props.onChange(value);
        };

        onCloseMenuOnScroll = event => { // FYI: This func uses for scrolling inside select portal - https://github.com/JedWatson/react-select/issues/3425 (Pasha, 29.10.2021)
            const {target: {classList} = {}} = event ?? {};

            return classList ? !classList.contains('select__menu-list') : true;
        };

        render = () => {
            const {isOpened} = this.state;
            const {className, isMulti, onBlur, disabled: isDisabled, isSearchable, isMenuPortal, ...props} = this.props;
            const selectCommonProps = {
                ...props,
                isOpened,
                isMulti,
                isDisabled,
                isSearchable,
                filterOption: props.filterOption ?? filterOption,
                classNamePrefix: 'select',
                className: classnames(styles['select'], '_select', className),
                closeMenuOnSelect: !isMulti,
                hideSelectedOptions: false,
                blurInputOnSelect: true,
                components: {DropdownIndicator},
                onMenuOpen: this.onOpen,
                onMenuClose: this.onClose,
                onChange: this.onChange,
                ...isMenuPortal && {
                    menuPortalTarget: document.body,
                    closeMenuOnScroll: this.onCloseMenuOnScroll,
                    styles: {menuPortal: base => ({...base, zIndex: 10000001})} // FYI: We use this magic number for z-index for set order over popup (Pasha, 4.08.2021)
                }
            };

            return <Component {...selectCommonProps}/>;
        };
    }

    WithSelectCommon.propTypes = {
        isMenuPortal: PropTypes.bool,
        isMulti: PropTypes.bool,
        disabled: PropTypes.bool,
        isSearchable: PropTypes.bool,
        filterOption: PropTypes.func,
        className: PropTypes.string,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func
    };

    WithSelectCommon.defaultProps = {
        className: '',
        disabled: false,
        isSearchable: false,
        isMenuPortal: false
    };

    return WithSelectCommon;
};

export {withSelectCommon as testableWithSelectCommon};
export default withSelectCommon;
