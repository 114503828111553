import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const Radio = ({className, caption, fieldValue, value, disabled, ...props}) => {
    const isChecked = value === fieldValue;

    return (
        <label className={classnames(styles['radio'], '_radio', {[styles['radio_disabled']]: disabled}, className)}>
            <input type='radio' className={styles['radio__input']} checked={isChecked} value={fieldValue} disabled={disabled} {...props}/>
            <div className='_radio-checkmark'/>
            <div className={styles['radio__caption']}>{caption}</div>
        </label>
    );
};

Radio.propTypes = {
    value: PropTypes.any,
    disabled: PropTypes.bool,
    fieldValue: PropTypes.any.isRequired,
    className: PropTypes.string,
    caption: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    onChange: PropTypes.func
};

Radio.defaultProps = {
    className: '',
    caption: ''
};

export {Radio as TestableRadio};
export default Radio;
