import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import {isBoolean} from '../../utils';
import styles from './index.module.scss';

const DEFAULT_Z_INDEX = 10000001;

const Tooltip = props => {
    const {
        isOpened,
        isClickable,
        disabled,
        direction,
        content,
        children,
        maxWidth,
        className,
        zIndex
    } = props;

    const tooltipProps = {
        content,
        zIndex,
        maxWidth,
        interactive: true,
        placement: direction,
        duration: 0,
        theme: 'light',
        trigger: 'mouseenter',
        ...isBoolean(isOpened) && {visible: isOpened},
        ...isClickable && {trigger: 'click'},
        ...disabled && {trigger: 'manual'}
    };

    return (
        <Tippy {...tooltipProps}>
            <div className={classnames(styles['tooltip-initiator'], className)}>{children}</div>
        </Tippy>
    );
};

Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    disabled: PropTypes.bool,
    isOpened: PropTypes.bool,
    isClickable: PropTypes.bool,
    className: PropTypes.string,
    // FYI: Use 'none' value to no maxWidth tooltip content (Pasha, 3.03.2022)
    maxWidth: PropTypes.oneOf([PropTypes.number, 'none']),
    zIndex: PropTypes.number,
    direction: PropTypes.string
};

Tooltip.defaultProps = {
    content: '',
    direction: 'top',
    zIndex: DEFAULT_Z_INDEX,
    maxWidth: 260
};

export {Tooltip as TestableTooltip};
export default React.memo(Tooltip);
