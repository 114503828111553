import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const CLASS_NAME_BY_TYPE = {
    default: styles['label-wrapper_default'],
    input: styles['label-wrapper_input'],
    select: styles['label-wrapper_select'],
    checkbox: styles['label-wrapper_checkbox'],
    radio: styles['label-wrapper_radio'],
    'radio-group': styles['label-wrapper_radio-group'],
    switcher: styles['label-wrapper_switcher'],
    datepicker: styles['label-wrapper_datepicker'],
    textarea: styles['label-wrapper_textarea'],
    file: styles['label-wrapper_file']
};

const Label = ({className, children, label, caption, type, isInline, isRequired}) => {
    const labelClassName = classnames(styles['label-wrapper'], CLASS_NAME_BY_TYPE[type], className, {
        [styles['label-wrapper_inline']]: isInline,
        [styles['label-wrapper_required']]: isRequired
    });

    return (
        <div className={labelClassName}>
            {(label || caption) && (
                <div className={styles['label-wrapper-header']}>
                    <div className={styles['label-wrapper-header__title']}>{label}</div>
                    <div className={styles['label-wrapper-header__caption']}>{caption}</div>
                </div>
            )}

            <div className={styles['label-wrapper__content']}>{children}</div>
        </div>
    );
};

Label.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string,
    isInline: PropTypes.bool,
    isRequired: PropTypes.bool
};

Label.defaultProps = {
    type: 'default',
    className: ''
};

export {Label as TestableLabel};
export default React.memo(Label);
