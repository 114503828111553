import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useTable, useGlobalFilter, useSortBy, usePagination, useFlexLayout, useAsyncDebounce, useExpanded} from 'react-table';
import {useSticky} from 'react-table-sticky';
import Heading from '../Heading';
import Input from '../Input';
import Icon from '../Icon';
import Cell from './Cell';
import Pagination from './Pagination';
import {getItemKeyValue, isBoolean, isEmpty, negateFunc} from '../../utils';
import {DEFAULT_DEBOUNCE_DELAY} from '../../constants';
import emptyTableImage from '../../static/images/empty_table.svg';
import styles from './index.module.scss';

const SORTING_DIRECTIONS = {asc: 'asc', desc: 'desc'};

const Table = props => {
    const {
        className,
        columns,
        data,
        title,
        ActionBar,
        unit,
        unitCount,
        pageSize,
        isFilterable,
        isSortable,
        filterDescription,
        onRowClick,
        onFetchData,
        getSubRow,
        getRowProps,
        getCellProps
    } = props;
    const [filterQuery, setFilterQuery] = useState('');

    const getFormattedColumn = isSortableTable => ({isSortable, isFilterable = true, ...column}) => {
        const isSortingAvailable = isSortable ?? isSortableTable;

        return {
            ...column,
            // TODO: Right now "defaultCanSort" option doesn't work as written in the documentation, so we have to use "disableSortBy" option (17.12.2021, Slava)
            disableSortBy: !isSortingAvailable,
            disableGlobalFilter: !isFilterable
        };
    };
    const tableConfig = {
        data,
        columns: useMemo(() => columns.filter(negateFunc(getItemKeyValue('isHidden'))).map(getFormattedColumn(isSortable)), [columns, isSortable]),
        initialState: {pageSize},
        ...(onFetchData ? {
            manualPagination: true,
            autoResetSortBy: false,
            autoResetGlobalFilter: false,
            pageCount: Math.ceil(unitCount / pageSize)
        } : {})
    };

    const {
        state: {pageIndex, globalFilter: filterBy, sortBy: sorting},
        page: rows,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setGlobalFilter,
        pageCount,
        canPreviousPage,
        canNextPage,
        gotoPage
    } = useTable(tableConfig, useGlobalFilter, useSortBy, useExpanded, usePagination, useFlexLayout, useSticky);

    const setFilter = useAsyncDebounce(value => setGlobalFilter(value), DEFAULT_DEBOUNCE_DELAY);

    const onFilter = ({target}) => {
        setFilterQuery(target.value);
        setFilter(target.value);
    };

    const tableProps = {...getTableProps(), style: {minWidth: 'initial'}};
    const paginationProps = {
        pageIndex,
        pageSize,
        pageCount,
        canPreviousPage,
        canNextPage,
        gotoPage,
        unit,
        unitCount: onFetchData ? unitCount : data.length
    };

    const {id: sortBy, desc: isDesc} = sorting[0] ?? {};
    const sortingDirection = isDesc ? SORTING_DIRECTIONS.desc : SORTING_DIRECTIONS.asc;
    const orderBy = isBoolean(isDesc) ? sortingDirection : null;
    useEffect(() => {
        if (onFetchData) {
            onFetchData({pageIndex, pageSize, filterBy, sortBy, orderBy});
        }
    }, [onFetchData, pageIndex, pageSize, filterBy, sortBy, orderBy]);

    return (
        <div className={classnames(styles['table'], className)}>
            {(title || ActionBar) && (
                <div className={styles['table-header']}>
                    {title && <Heading className={styles['table-header__title']} gradation={4} fontWeight='semibold'>{title}</Heading>}
                    {ActionBar && <div className={styles['table-header__action-bar']}>{ActionBar}</div>}
                </div>
            )}

            {isFilterable && (
                <div className={styles['table-filtering']}>
                    <Input className={styles['table-filtering__input']}
                        value={filterQuery}
                        onChange={onFilter}
                        disabled={isEmpty(data) && !filterQuery}
                        icon='info-circle'
                        iconTooltipContent={filterDescription}
                        placeholder='Search...'/>
                </div>
            )}

            <div className={styles['table-data-grid']} {...tableProps}>
                <div className={styles['table-data-grid__header']}>
                    {headerGroups.map(headerGroup => (
                        <div className={classnames(styles['grid-row'])} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                const {align, canSort, isSorted, getHeaderProps, getSortByToggleProps, render} = column;
                                const cellClassName = classnames(styles['grid-row__cell'], {[styles[`grid-row__cell_${align}`]]: !!align});
                                const iconClassName = classnames(styles['sort-icon'], {[styles['sort-icon_active']]: isSorted});

                                return (
                                    <div className={cellClassName} {...getHeaderProps(getSortByToggleProps())}>
                                        <div className={styles['label']}>{render('Header')}</div>
                                        {canSort && <Icon className={iconClassName} type='sort1'/>}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>

                {!isEmpty(data) && (
                    <div className={styles['table-data-grid__body']} {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);

                            return (
                                <React.Fragment>
                                    <div className={classnames(styles['grid-row'])} {...row.getRowProps(getRowProps(row))} onClick={() => onRowClick(row)}>
                                        {row.cells.map(cell => {
                                            const cellClassName = classnames(
                                                styles['grid-row__cell'],
                                                {[styles[`grid-row__cell_${cell.column.align}`]]: cell.column.align}
                                            );

                                            return <Cell className={cellClassName} {...cell.getCellProps(getCellProps(cell))}>{cell.render('Cell')}</Cell>;
                                        })}
                                    </div>

                                    {row.isExpanded && getSubRow({row})}
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}

                {isEmpty(data) && (
                    <div className={styles['table-data-grid__no-data']}>
                        <img src={emptyTableImage} alt='' className={styles['no-data-image']}/>

                        <Heading gradation={2}>Nothing here...</Heading>
                    </div>
                )}
            </div>

            {pageCount > 1 && <Pagination className='table-pagination' {...paginationProps}/>}
        </div>
    );
};

Table.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    columns: PropTypes.arrayOf(PropTypes.shape({
        Header: PropTypes.string.isRequired,
        Cell: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
        accessor: PropTypes.string,
        width: PropTypes.number,
        minWidth: PropTypes.number,
        isSortable: PropTypes.bool,
        isFilterable: PropTypes.bool,
        sticky: PropTypes.oneOf(['left', 'right']),
        align: PropTypes.oneOf(['left', 'center', 'right']),
        isHidden: PropTypes.bool
    })),
    title: PropTypes.string,
    ActionBar: PropTypes.node,
    unit: PropTypes.string,
    unitCount: PropTypes.number,
    pageSize: PropTypes.number,
    isFilterable: PropTypes.bool,
    isSortable: PropTypes.bool,
    filterDescription: PropTypes.string,
    onRowClick: PropTypes.func,
    onFetchData: PropTypes.func,
    getSubRow: PropTypes.func,
    getRowProps: PropTypes.func,
    getCellProps: PropTypes.func
};

Table.defaultProps = {
    className: '',
    data: [],
    columns: [],
    pageSize: 50,
    unit: 'rows',
    isFilterable: true,
    isSortable: true,
    onRowClick: () => {},
    getSubRow: () => {},
    getRowProps: () => {},
    getCellProps: () => {}
};

export {Table as TestableTable};
export default Table;
