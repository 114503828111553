import React, {useState, useEffect, useRef} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import styles from './index.module.scss';

// TODO: think about custom tooltip in order to avoid unnecessary nesting (04.02.2020, Oleh);
const Cell = ({className, children, ...props}) => {
    const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);
    const contentElement = useRef(null);
    const tooltipProps = {content: children, className: styles['cell__tooltip'], disabled: isTooltipDisabled};

    useEffect(() => {
        const {scrollWidth, offsetWidth} = contentElement?.current || {};

        setIsTooltipDisabled(scrollWidth <= offsetWidth);
    }, []);

    return (
        <div className={classnames(styles['cell'], className)} {...props}>
            <Tooltip {...tooltipProps}>
                <div className={styles['cell__content']} ref={contentElement}>
                    {children}
                </div>
            </Tooltip>
        </div>
    );
};

Cell.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

Cell.defaultProps = {
    className: ''
};

export {Cell as TestableCell};
export default Cell;
