import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import withOutsideClick from '../../HOC/withOutsideClick';
import styles from './index.module.scss';

const CLASS_NAME_BY_ALIGNING = {
    left: styles['dropdown-wrapper-popup_left'],
    right: styles['dropdown-wrapper-popup_right'],
    center: styles['dropdown-wrapper-popup_center']
};

const DropdownWrapper = props => {
    const {children, className, renderDropdownInitiator, aligning, isOpened, onOpen, onClose, forwardRef} = props;

    return (
        <div className={classnames(styles['dropdown-wrapper'], className)} onClick={onOpen} ref={forwardRef}>
            {renderDropdownInitiator({isOpened})}

            {isOpened && (
                <div className={classnames(styles['dropdown-wrapper-popup'], CLASS_NAME_BY_ALIGNING[aligning])}>
                    {children({onClose})}
                </div>
            )}
        </div>
    );
};

DropdownWrapper.propTypes = {
    className: PropTypes.string,
    aligning: PropTypes.string,
    isOpened: PropTypes.bool.isRequired,
    renderDropdownInitiator: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

DropdownWrapper.defaultProps = {
    className: '',
    aligning: 'left'
};

export {DropdownWrapper as TestableDropdownWrapper};
export default withOutsideClick(DropdownWrapper);
