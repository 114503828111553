import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import withEnhancedField from '../../HOC/withEnhancedField';
import styles from './index.module.scss';

const MASK_BY_TYPE = {
    tel: '(999) 999-9999'
};

const Input = ({className, type, maskChar, mask: customMask, autoComplete, ...props}) => {
    const mask = customMask || MASK_BY_TYPE[type];
    const inputProps = {...props, type, className: classnames(styles['input'], '_input', className), autoComplete};
    const inputMaskProps = {...inputProps, maskChar, mask};

    return mask ? <InputMask {...inputMaskProps}/> : <input {...inputProps}/>;
};

Input.propTypes = {
    name: PropTypes.string,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

Input.defaultProps = {
    placeholder: 'Input text...',
    className: '',
    value: '',
    maskChar: 'X',
    autoComplete: 'off'
};

export {Input as TestableInput};
export default withEnhancedField(Input);
