import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';
import withEnhancedField from '../../HOC/withEnhancedField';
import {getFileFormat, isNumber} from '../../utils';
import styles from './index.module.scss';

const SIZES = {lg: 'lg', md: 'md'};

const CLASS_NAME_BY_SIZE = {
    [SIZES.lg]: styles['input-file_lg'],
    [SIZES.md]: styles['input-file_md']
};

class InputFile extends React.PureComponent {
    state = {isDragOver: false};

    onChange = ({target, dataTransfer}) => {
        const {accept, isMultiple, onChange} = this.props;
        const processedFiles = dataTransfer ? [...dataTransfer.files] : [...target.files];
        const acceptableFiles = processedFiles.filter(file => file && accept.includes(getFileFormat(file.name)));

        const files = accept.length ? acceptableFiles : processedFiles;
        const [singleFile] = files;

        if (!singleFile) {
            return false;
        }

        return onChange(isMultiple ? files : singleFile);
    };

    onResetValue = ({target}) => {
        target.value = null;
    };

    onDragOver = event => {
        event.preventDefault();

        this.setState({isDragOver: true});
    };

    onDragLeave = () => this.setState({isDragOver: false});

    onDrop = async event => {
        event.preventDefault();

        if (this.props.disabled) {
            return false;
        }

        await this.onChange(event);
        this.setState({isDragOver: false});
    };

    getFileFormat = type => type.substring(1).toUpperCase();

    render = () => {
        const {isDragOver} = this.state;
        const {className, name, isImage, isMultiple, value, isAvatar, disabled, onBlur, onFocus, accept, maxSize, filesLeft, size} = this.props;

        // FYI: filesLeft can be one of null, undefined or number, that's why we need to check if filesLeft is number to be sure condition is correct (29.06.2021, Oleh);
        const isFileCountLimitReached = isMultiple && isNumber(filesLeft) && filesLeft <= 0;
        const isDisabled = disabled || isFileCountLimitReached;
        const isImageShowed = isImage && value;

        const inputProps = {name, disabled: isDisabled, onBlur, onFocus, multiple: isMultiple, accept: accept ? accept.join(',') : null, onClick: this.onResetValue, onChange: this.onChange};
        const fileDropProps = {onDrop: this.onDrop, onDragLeave: this.onDragLeave, onDragOver: this.onDragOver};

        const inputFileClassName = classnames(styles['input-file'], className, CLASS_NAME_BY_SIZE[size], {
            [styles['input-file_avatar']]: isImage && isAvatar,
            [styles['input-file_logo']]: isImage && !isAvatar,
            [styles['input-file_disabled']]: isDisabled
        });
        const dropClassName = classnames(styles['input-file-drop'], '_input-file-drop', {
            [styles['input-file-drop_drag-over']]: isDragOver,
            [styles['input-file-drop_image-loaded']]: isImageShowed
        });
        const dropAreaClassName = classnames(styles['input-file-drop-area'], {[styles['input-file-drop-area_hidden']]: isImageShowed});

        return (
            <div className={classnames(styles['input-file-wrapper'], '_input-file-wrapper')}>
                <label className={inputFileClassName}>
                    <div className={dropClassName} {...fileDropProps}>
                        <div className={dropAreaClassName}>
                            <div className={styles['input-file-drop-area__icon-wrapper']}>
                                <Icon className={styles['drop-area-icon']} type='upload'/>
                            </div>
                            <div className={styles['input-file-drop-area-text']}>
                                {!isFileCountLimitReached && (
                                    <React.Fragment>
                                        <div>Drag & Drop or <span className={styles['input-file-drop-area-text_underline']}>Select File</span></div>

                                        {filesLeft && <div>You can only upload <span className={styles['input-file-drop-area-text_highlighted']}>{filesLeft}</span> files</div>}
                                    </React.Fragment>
                                )}

                                {isFileCountLimitReached && <React.Fragment>You have uploaded the maximum number of files</React.Fragment>}
                            </div>
                        </div>

                        {isImageShowed && <img className={styles['input-file-drop__image']} src={value} alt=''/>}
                    </div>

                    <input type='file' className={styles['input-file__input']} {...inputProps}/>
                </label>

                <div className={styles['upload-description']}>
                    {!!accept.length && (
                        <div className={styles['upload-description__formats']}>
                            File formats: {accept.map(this.getFileFormat).join(', ')}
                        </div>
                    )}

                    {maxSize && <div className={styles['upload-description__size']}>Max size: {maxSize}Mb</div>}
                </div>
            </div>
        );
    }
}

InputFile.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    className: PropTypes.string,
    isImage: PropTypes.bool,
    isAvatar: PropTypes.bool,
    isMultiple: PropTypes.bool,
    size: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    accept: PropTypes.arrayOf(PropTypes.string),
    maxSize: PropTypes.number,
    filesLeft: PropTypes.number
};

InputFile.defaultProps = {
    className: '',
    isImage: false,
    isAvatar: false,
    isMultiple: false,
    size: 'md',
    accept: []
};

export default withEnhancedField(InputFile);
