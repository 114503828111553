export const DEFAULT_FONT_FAMILY = '"Source Sans Pro", sans-serif';

export const INPUT_TYPES = {
    hidden: 'hidden',
    text: 'text',
    number: 'number',
    checkbox: 'checkbox',
    radio: 'radio'
};

export const CHAR_CODES = {
    bull: 8226
};

export const IS_MS_BROWSER = ['MSIE ', 'Trident/', 'Edge/'].some(detectionItem => window.navigator.userAgent.includes(detectionItem));

export const KEY_CODES = {backspace: 8, delete: 46};

export const KEY_NAMES = {enter: 'Enter', tab: 'Tab'};

export const DEFAULT_DEBOUNCE_DELAY = 300;
