import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const CLASS_NAME_BY_WEIGHT = {
    light: styles['text_light'],
    regular: styles['text_regular'],
    semibold: styles['text_semibold'],
    bold: styles['text_bold']
};

const Text = ({children, className, isSmall, isItalic, isInline, fontWeight, ...props}) => {
    const textClassName = classnames(styles['text'], CLASS_NAME_BY_WEIGHT[fontWeight], className, {
        [styles['text_inline']]: isInline,
        [styles['text_small']]: isSmall,
        [styles['text_italic']]: isItalic
    });

    return (
        <div className={textClassName} {...props}>
            {children}
        </div>
    );
};

Text.propTypes = {
    className: PropTypes.string,
    fontWeight: PropTypes.string,
    isSmall: PropTypes.bool,
    isItalic: PropTypes.bool,
    isInline: PropTypes.bool,
    children: PropTypes.node
};

Text.defaultProps = {
    className: '',
    fontWeight: 'regular'
};

export {Text as TestableText};
export default React.memo(Text);
