import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputFile from './InputFile';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import Link from '../Link';
import {getEqual, negateFunc, bytesToMegabytes, getFileName} from '../../utils';
import styles from './index.module.scss';

class FileUploader extends React.PureComponent {
    onChange = async value => {
        const {value: currentValue, maxSize = Infinity, isMultiple, onUpload, onChange} = this.props;
        const filesLeft = this.getFilesLeft([].concat(currentValue).filter(Boolean));
        const values = [].concat(value).slice(0, filesLeft ?? undefined);
        const {validValues, invalidValues} = values.reduce((acc, item) => {
            const isValidSize = bytesToMegabytes(item.size) <= maxSize;

            return {
                validValues: isValidSize ? [...acc.validValues, item] : acc.validValues,
                invalidValues: !isValidSize ? [...acc.invalidValues, item] : acc.invalidValues
            };
        }, {validValues: [], invalidValues: []});

        const rejectedFiles = invalidValues.map(({name}) => {
            const error = new Error(`Your uploaded file is too big. Maximum file size is: ${maxSize}Mb`);
            error.name = name;

            return error;
        });
        const uploadedFiles = await Promise.all(validValues.map(onUpload));

        const updatedValues = [...uploadedFiles, ...rejectedFiles].concat(currentValue).filter(Boolean);

        onChange(isMultiple ? updatedValues : updatedValues[0]);
    };

    onDeleteFile = file => {
        const {value, onChange, isMultiple} = this.props;
        const updatedValues = [].concat(value).filter(negateFunc(getEqual(file)));

        onChange(isMultiple ? updatedValues : updatedValues[0]);
    };

    getFilesLeft = (files = []) => {
        const {isMultiple, maxCount} = this.props;

        return isMultiple && maxCount ? maxCount - files.length : null;
    };

    getFilePreview = (file, index) => {
        const {isDownloadable, accessToken} = this.props;
        const isError = file instanceof Error;
        const accessTokenQuery = !isError && accessToken ? `${file.includes('?') ? '&' : '?'}access_token=${accessToken}` : '';
        const url = !isError && (isDownloadable ? `${file}${accessTokenQuery}` : null);
        const fileName = isError ? file.name : getFileName(file);
        const onDelete = () => this.onDeleteFile(file);

        return (
            <div key={index} className={styles['file-preview']}>
                <div className={styles['preview-info']}>
                    {isError && (
                        <Tooltip content={file.message}>
                            <Icon type='alert-info' className={classnames(styles['preview-icon'], styles['preview-icon_error'])}/>
                        </Tooltip>
                    )}
                    {!isError && <Icon type='check-circle' className={classnames(styles['preview-icon'], styles['preview-icon_success'])}/>}

                    {url && <Link href={url} className={styles['preview-info__name']}>{fileName}</Link>}
                    {!url && <span className={styles['preview-info__name']}>{fileName}</span>}
                </div>

                <Icon type='trash' onClick={onDelete} className={classnames(styles['preview-icon'], styles['preview-icon_trash'])}/>
            </div>
        );
    };

    render = () => {
        const {value, isImage} = this.props;
        const values = [].concat(value).filter(Boolean);
        const filesLeft = this.getFilesLeft(values);

        return (
            <div className={styles['file-uploader']}>
                <InputFile {...this.props} filesLeft={filesLeft} onChange={this.onChange}/>

                {!isImage && values.map(this.getFilePreview)}
            </div>
        );
    }
}

FileUploader.propTypes = {
    onUpload: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    maxSize: PropTypes.number,
    maxCount: PropTypes.number,
    value: PropTypes.any,
    isMultiple: PropTypes.bool,
    isImage: PropTypes.bool,
    isDownloadable: PropTypes.bool,
    accessToken: PropTypes.string
};

FileUploader.defaultProps = {
    isDownloadable: true,
    accessToken: null
};

export default FileUploader;
