import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const CLASS_NAME_BY_GRADATION = {
    1: styles['heading_gradation-1'],
    2: styles['heading_gradation-2'],
    3: styles['heading_gradation-3'],
    4: styles['heading_gradation-4'],
    5: styles['heading_gradation-5'],
    6: styles['heading_gradation-6']
};

const CLASS_NAME_BY_WEIGHT = {
    light: styles['heading_light'],
    regular: styles['heading_regular'],
    semibold: styles['heading_semibold'],
    bold: styles['heading_bold']
};

const Heading = ({children, className, gradation, fontWeight, ...props}) => {
    const gradationClassName = CLASS_NAME_BY_GRADATION[gradation];
    const fontWeightClassName = CLASS_NAME_BY_WEIGHT[fontWeight];

    return (
        <div {...props} className={classnames(styles['heading'], gradationClassName, fontWeightClassName, className)}>
            {children}
        </div>
    );
};

Heading.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    gradation: PropTypes.number,
    fontWeight: PropTypes.string
};

Heading.defaultProps = {
    gradation: 5,
    fontWeight: 'regular',
    className: ''
};

export {Heading as TestableHeading};
export default React.memo(Heading);
