import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Radio from '../Radio';
import withEnhancedField from '../../HOC/withEnhancedField';
import styles from './index.module.scss';

class RadioGroup extends React.PureComponent {
    getOption = (item, index) => {
        const {value, labelField, valueField, disabled, disabledOptions, ...props} = this.props;
        const caption = item[labelField];
        const fieldValue = item[valueField];
        const optionProps = {
            ...props,
            caption,
            fieldValue,
            value,
            disabled: disabled || disabledOptions.includes(fieldValue) || item.disabled
        };

        return <Radio key={index} {...optionProps}/>;
    };

    render = () => {
        const {className, isInline, options} = this.props;
        const radioGroupClassName = classnames(styles['radio-group'], '_radio-group', className, {
            [styles['radio-group_inline']]: isInline,
            '_radio-group_inline': isInline
        });

        return (
            <div className={radioGroupClassName}>
                {options.map(this.getOption)}
            </div>
        );
    };
}

RadioGroup.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array,
    className: PropTypes.string,
    isInline: PropTypes.bool,
    labelField: PropTypes.string,
    valueField: PropTypes.string,
    disabled: PropTypes.bool,
    disabledOptions: PropTypes.array
};

RadioGroup.defaultProps = {
    className: '',
    isInline: false,
    labelField: 'label',
    valueField: 'value',
    options: [],
    disabledOptions: []
};

export {RadioGroup as TestableRadioGroup};
export default withEnhancedField(RadioGroup);
