import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import styles from './index.module.scss';

const TYPES = {
    primary: 'primary',
    danger: 'danger',
    light: 'light',
    success: 'success',
    successReverse: 'success-reverse',
    secondary: 'secondary'
};

const SIZES = {lg: 'lg', md: 'md', sm: 'sm'};

const CLASS_NAME_BY_TYPE = {
    [TYPES.primary]: styles['button_primary'],
    [TYPES.danger]: styles['button_danger'],
    [TYPES.light]: styles['button_light'],
    [TYPES.success]: styles['button_success'],
    [TYPES.successReverse]: styles['button_success-reverse'],
    [TYPES.secondary]: styles['button_secondary']
};

const CLASS_NAME_BY_SIZE = {
    [SIZES.lg]: styles['button_lg'],
    [SIZES.md]: styles['button_md'],
    [SIZES.sm]: styles['button_sm']
};

const LinkTo = ({children, href, type, size, isButton, className, ...props}) => {
    const isInternal = href.startsWith('/');
    const linkClassName = isButton ? classnames(styles['button'], CLASS_NAME_BY_TYPE[type], CLASS_NAME_BY_SIZE[size], className) : classnames(styles['link'], className);
    const externalProps = {...props, href, className: linkClassName};
    const internalProps = {to: href, className: linkClassName};

    return isInternal ? <Link {...internalProps}>{children}</Link> : <a {...externalProps}>{children}</a>;
};

LinkTo.propTypes = {
    isButton: PropTypes.bool,
    type: PropTypes.string,
    href: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

LinkTo.defaultProps = {
    href: '',
    type: 'primary',
    size: 'md',
    className: ''
};

export {LinkTo as TestableLink};
export default React.memo(LinkTo);
